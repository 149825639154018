import nikeC from "./images/shoes/NikeC.png";
import AirForce from "./images/shoes/AirForce.png";
import AirForce1 from "./images/shoes/AirForce1.png";
import SoccerShoes from "./images/shoes/SoccerShoes.png";
import clothes1 from "./images/clothes/Rectangle 22-1.png";
import clothes2 from "./images/clothes/Rectangle 22-2.png";
import clothes3 from "./images/clothes/Rectangle 22-3.png";
import clothes4 from "./images/clothes/Rectangle 22.png";
import accessories1 from "./images/accessories/Rectangle 22-1.png";
import accessories2 from "./images/accessories/Rectangle 22-2.png";
import accessories3 from "./images/accessories/Rectangle 22-3.png";
import accessories4 from "./images/accessories/Rectangle 22.png";

const products = [
  {
    name: "Nike Court Zoom Cage 2",
    price: 4699,
    image: nikeC,
    category: "shoes",
  },
  {
    name: "Air Force 1 Ultra",
    price: 6799,
    image: AirForce,
    category: "shoes",
  },
  {
    name: "Air Force 1 Ultra Flyknit",
    price: 3129,
    image: AirForce1,
    category: "shoes",
  },
  {
    name: "Men’s Soccer Shoes",
    price: 2699,
    image: SoccerShoes,
    category: "shoes",
  },
  {
    name: "Nike Court Zoom Cage 2",
    price: 4699,
    image: clothes1,
    category: "clothes",
  },
  {
    name: "Air Force 1 Ultra",
    price: 6799,
    image: clothes2,
    category: "clothes",
  },
  {
    name: "Air Force 1 Ultra Flyknit",
    price: 3129,
    image: clothes3,
    category: "clothes",
  },
  {
    name: "Men’s Soccer Shoes",
    price: 2699,
    image: clothes4,
    category: "clothes",
  },
  {
    name: "Nike Court Zoom Cage 2",
    price: 4699,
    image: accessories4,
    category: "accessories",
  },
  {
    name: "Air Force 1 Ultra",
    price: 6799,
    image: accessories1,
    category: "accessories",
  },
  {
    name: "Air Force 1 Ultra Flyknit",
    price: 3129,
    image: accessories2,
    category: "accessories",
  },
  {
    name: "Men’s Soccer Shoes",
    price: 2699,
    image: accessories3,
    category: "accessories",
  },
];

export default products;
