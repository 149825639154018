import React from "react";
import styles from "./ProductsMain.module.scss";
import products from "../../data/data";
import ProductsItems from "./ProductsItems";

const ProductsMain = () => {
  const shoes = products.filter((product) => product.category === "shoes");
  const clothes = products.filter((product) => product.category === "clothes");
  const accessories = products.filter(
    (product) => product.category === "accessories"
  );

  return (
    <div className={styles.products}>
      <ProductsItems type="Обувь" items={shoes} />
      <ProductsItems type="Одежда" items={clothes} />
      <ProductsItems type="Аксессуары" items={accessories} />
    </div>
  );
};

export default ProductsMain;
