import React from "react";
import styles from "./Header.module.scss";
import logo from "../../images/logo.png";
import { CiSearch, CiShoppingCart, CiStar, CiUser } from "react-icons/ci";

const Header = () => {
  return (
    <header className={styles.header}>
      <section className={styles.section}>
        <a href="/">
          <img src={logo} alt="" />
        </a>

        <nav>
          <ul>
            <li>
              <a href="#clothing">Одежда</a>
            </li>
            <li>
              <a href="#footwear">Обувь</a>
            </li>
            <li>
              <a href="#accessories">Аксессуары</a>
            </li>
            <li>
              <a href="#brands">Бренды</a>
            </li>
            <li>
              <a href="#pricing">Расчет стоимости</a>
            </li>
            <li>
              <a href="#info">Информация</a>
            </li>
          </ul>
        </nav>
        <div className={styles.headerRight}>
          <CiSearch
            size={24}
            fill="white"
            style={{ stroke: "white", strokeWidth: 0.7 }}
          />
          <CiStar
            size={24}
            fill="white"
            style={{ stroke: "white", strokeWidth: 0.7 }}
          />
          <CiUser
            size={24}
            fill="white"
            style={{ stroke: "white", strokeWidth: 0.7 }}
          />
          <div>
            <CiShoppingCart
              size={24}
              fill="white"
              style={{ stroke: "white", strokeWidth: 0.7 }}
            />
            <p>11 889p</p>
            <span>7</span>
          </div>
        </div>
      </section>
    </header>
  );
};

export default Header;
