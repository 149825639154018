import React from "react";
import styles from "./ProductsMain.module.scss";
import star from "../../images/buttons/star.png";
const ProductsItems = ({ type, items }) => {
  return (
    <div className={styles.productsMain}>
      <div className={styles.productsMainHeader}>
        <h2>{type}</h2>
        <button className={styles.productsMainHeaderButton}>
          больше товаров {" >"}
        </button>
      </div>
      <div className={styles.productsMainItems}>
        {items.map((item, idx) => (
          <div key={idx} className={styles.productsMainItem}>
            <button className={styles.productsMainItemButtonAdd}>
              <img src={star} alt="add" />
            </button>
            <img src={item.image} alt={item.name} />
            <h3>{item.name}</h3>
            <span>от {item.price} ₽</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsItems;
