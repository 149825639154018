import React from "react";
import Rectangle from "../components/rectangle/Rectangle";
import ProductsMain from "../components/productsMain/ProductsMain";
import Calculate from "../components/calculate/Calculate";

const Main = () => {
  return (
    <main>
      <Rectangle />
      <ProductsMain />
      <Calculate />
    </main>
  );
};

export default Main;
