import React from "react";
import styles from "./Calculate.module.scss";

const Calculate = () => {
  return (
    <section className={styles.calculateBG}>
      <div className={styles.calculate}>
        <div className={styles.calculateLeftText}>
          <h1>
            Рассчитать <br /> стоимость
          </h1>
          <span>
            Если вам не удалось найти то, что искали, вы всегда можете
            воспользоваться автоматическим расчетом стоимость заказа на
            маркетплейсе Poizon, включая комиссию сервиса и доставку
          </span>
        </div>
        <div className={styles.calculateRigthImage}></div>
      </div>
    </section>
  );
};

export default Calculate;
