import React from "react";
import styles from "./Rectangle.module.scss";

const Rectangle = () => {
  return (
    <section className={styles.rectangle}>
      <div className={styles.rectangleText}>
        <h1>
          ШИРОКИЙ <br /> АССОРТИМЕНТ <br /> ОДЕЖДЫ
        </h1>
        <p>
          Одежда от известные брендов у нас в каталоге.
          <br /> Только качественные вещи.
        </p>
        <button className={styles.rectangleCatalogeButton}>
          <p>Перейти в каталог</p> <span>{">"}</span>
        </button>
        <div>
          <button className={styles.rectangleButtonSwitch}>{"<"}</button>
          <button className={styles.rectangleButtonSwitch}>{">"}</button>
        </div>
      </div>
    </section>
  );
};

export default Rectangle;
